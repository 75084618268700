html,
body,
#root,
.App {
  margin: 0;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chart-container {
  flex: 1;
}
